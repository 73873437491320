import React from 'react';
import {Alert, Button} from 'antd';
import styled from 'styled-components';

function ProductAlertDisplay(props) {
  const {user, product, passedNIn1Product} = props;

  const isMissUserData =
    user &&
    user.data &&
    (!user.data.name || !user.data.phone || !user.data.email);

  const isNIn1Product = !!passedNIn1Product;

  const isVidoProduct =
    product &&
    product.labels &&
    Array.isArray(product.labels) &&
    product.labels.findIndex((label) => label.indexOf('影音課程') > -1) > -1;

  return (
    <Wrapper>
      {isMissUserData && (
        <Alert
          showIcon
          type="warning"
          className="alert"
          message={
            <div>
              {`提醒您：新系統上線，請至「會員服務 > 個人資訊」填寫姓名、EMAIL
              與手機，以確保您報名課程活動之權益。`}
            </div>
          }
        />
      )}

      {isNIn1Product && (
        <Alert
          showIcon
          type="warning"
          className="alert"
          message={
            <div>
              提醒您：本課程為系列課程，請至
              <Button
                type="link"
                target="_blank"
                className="alert-content-button"
                href={`/product/?id=${passedNIn1Product.id}`}>
                {passedNIn1Product.name}
              </Button>
              報名。
            </div>
          }
        />
      )}

      {isVidoProduct && (
        <Alert
          showIcon
          type="warning"
          className="alert"
          message={
            <div>
              提醒您：本影片觀看期限，按報名完成起算
              <span className="alert-content-red-highlight">
                {product.expired_day}
              </span>
              天，若遇結束日期
              <span className="alert-content-red-highlight">
                {product.onshelf_end_date}
              </span>
              將提前結束。
            </div>
          }
        />
      )}

      {!isNIn1Product && (
        <Alert
          showIcon
          type="warning"
          className="alert"
          message={
            <div>
              提醒您：報名完成以
              <span className="alert-content-red-highlight">繳費成功</span>
              為限，未繳費視為未報名。
            </div>
          }
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 20px;

  .alert {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.95rem;
  }

  .alert-content-button {
    padding: 0px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .alert-content-red-highlight {
    margin-left: 5px;
    margin-right: 5px;
    color: #f03e3e;
    font-weight: 500;
  }
`;

export default ProductAlertDisplay;
